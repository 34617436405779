import { createStore } from 'vuex'

// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';


import { createToaster } from "@meforma/vue-toaster";


const toaster = createToaster({ position: 'bottom' });



export default createStore({

  state: { loading: false, langIcon: '', langLangauge: '', isActive: false, searchOpen: false, user: null, songlist: {}, playlist: [], currentSong: {}, playStatus: false, credits: 0, creditsMode: false, shortcuts: true },
  getters: {
    loading: (state) => { return state.loading },
    langIcon: (state) => { return state.langIcon },
    langLangauge: (state) => { return state.langLangauge },
    searchOpen: (state) => { return state.searchOpen },
    songlist: (state) => { return state.songlist },
    playlist: (state) => { return state.playlist },
    currentSong: (state) => { return state.currentSong },
    playStatus: (state) => { return state.playStatus },
    credits: (state) => { return state.credits },
    creditsMode: (state) => { return state.creditsMode },
    shortcuts: (state) => { return state.shortcuts },
    user: (state) => { return state.user }
  },
  mutations: {
    setLoading(state, payload) {
      console.log('setLoading', payload)
      state.loading = payload || false
    },
    changeLang(state, payload) {
      localStorage.setItem('currentLanguage', payload.id);
      localStorage.setItem('currentLanguageIcon', payload.icon);
      state.langIcon = payload.icon || 'flag-icon-us'
      state.langLangauge = payload.id || 'EN'
      // window.location.reload();
    },
    change(state) {
      state.isActive = !state.isActive
    },

    search_open() {
      this.$store.state.searchOpen = true
    },
    search_close() {
      this.$store.state.searchOpen = false
      body.classList.remove('offcanvas')
    },

    setUser(state, payload) {
      state.user = payload || null
      if (payload) {
        localStorage.setItem('user', JSON.stringify(payload));
      }
      else {
        localStorage.removeItem('user')
      }
    },
    setSonglist(state, payload) {
      state.songlist = payload || {}
    },
    setPlaylist(state, payload) {
      state.playlist = payload || []
      localStorage.setItem('playlist', JSON.stringify(state.playlist));
    },
    addToPlaylist(state, payload) {
      //VERIFICA SE JÁ ESTA NA PLAYLIST      
      if (state.playlist.findIndex(obj => obj[1] === payload.song[1]) < 0) {
        //MUSICA NOVA NA LISTA
        if (Number.isInteger(payload.position)) { // SE POSICAO DEFINIDA ADICIONA NA POSIÇÃO
          state.playlist.splice(payload.position, 0, payload.song);
        } else { // DO CONTRARIO ADICIONA AO FINAL
          state.playlist.push(payload.song)
        }
        if (payload.messages) toaster.success(`Música adicionada com sucesso`);
      }
      else {
        if (payload.messages) toaster.warning(`Oops! Esta música já está presente na sua playlist`)
      }

      localStorage.setItem('playlist', JSON.stringify(state.playlist));

    },
    removeFromPlaylist(state, payload) {
      var index = state.playlist.findIndex(obj => obj[1] === payload[1])
      if (payload && index >= 0) {
        state.playlist.splice(index, 1);

        if (state.currentSong = true && payload[1] == state.currentSong[1]) {
          state.currentSong = null
          localStorage.setItem('currentSong', null);
        }

        toaster.success(`Música removida com sucesso da playlist`);
      }
      localStorage.setItem('playlist', JSON.stringify(state.playlist));
    },
    setCurrentSong(state, payload) {
      if (payload && payload.song) {
        state.currentSong = payload.song
        localStorage.setItem('currentSong', JSON.stringify(state.currentSong));
      }
    },
    setPlayStatus(state, payload) {
      console.log('setPlayStatus', state, payload);
      state.playStatus = payload
    },
    addCredits(state, payload) {
      state.credits += payload;
    },
    removeCredits(state, payload) {
      state.credits = state.credits > 0 ? state.credits - payload : 0;
    },
    resetCredits(state, payload) {
      state.credits = 0;
    },
    setCreditsMode(state, payload) {
      state.credits = 0;
      state.creditsMode = payload;
    },
    setShortcuts(state, payload) {
      console.log('setShortcuts', payload)
      state.shortcuts = payload || false
    },
  },
  actions: {
    setLoading({ commit, dispatch }, payload) {
      commit('setLoading', payload);
    },
    setLang({ commit, dispatch }, payload) {
      commit('changeLang', payload);
    },
    setUser({ commit, dispatch }, payload) {
      commit('setUser', payload);
    },
    setSonglist({ commit, dispatch }, payload) {
      commit('setSonglist', payload);
    },
    setPlaylist({ commit, dispatch }, payload) {
      commit('setPlaylist', payload);
    },
    addToPlaylist({ commit, dispatch }, payload) {
      commit('addToPlaylist', payload);
    },
    removeFromPlaylist({ commit, dispatch }, payload) {
      commit('removeFromPlaylist', payload);
    },
    setCurrentSong({ commit, dispatch }, payload) {
      commit('setCurrentSong', payload);
    },
    setPlayStatus({ commit, dispatch }, payload) {
      commit('setPlayStatus', payload);
    },
    addCredits({ commit, dispatch }, payload) {
      commit('addCredits', payload);
    },
    removeCredits({ commit, dispatch }, payload) {
      commit('removeCredits', payload);
    },
    resetCredits({ commit, dispatch }, payload) {
      commit('resetCredits', payload);
    },
    setCreditsMode({ commit, dispatch }, payload) {
      commit('setCreditsMode', payload);
    },
    setShortcuts({ commit, dispatch }, payload) {
      commit('setShortcuts', payload);
    }
  },
  modules: {
    layout,
    menu,
  }
});


